import React from 'react';
import ReactDOM from 'react-dom/client';
import ඞ from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/misc/Loading';
import { TitleProvider } from './useTitle';
import ErrorFallback from './components/misc/ErrorFallback';
import './i18n';
import { LicenseInfo } from '@mui/x-license-pro';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Package from '../package.json';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOM.createRoot(document.getElementById('root'));

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: Package.version
});

BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

root.render(
    <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter basename={baseUrl}>
                <React.Suspense fallback={<Loading />}>
                    <TitleProvider>
                        <ඞ />
                    </TitleProvider>
                </React.Suspense>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
