import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import payStatusMap from '../payStatusMap.json';
import useBalanceCard from '../useBalanceCard';
import { Box, Button } from '@mui/material';
import _ from 'lodash';

function TopupFinished() {
    const { t } = useTranslation(['translate', 'error', 'balanceCards']);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const { setSerialNumber } = useBalanceCard();
    const navigate = useNavigate();

    const [status, setStatus] = useState();
    const [statusId, setStatusId] = useState();

    useEffect(() => {
        const statusId = searchParams.get('orderStatusId');
        const payStatus = payStatusMap[statusId];

        setStatusId(statusId);
        setStatus(payStatus);
    }, [searchParams, payStatusMap]);

    useEffect(() => {
        setSerialNumber(params.serialNumber);
    }, [params]);

    return (
        <Box
            sx={{
                textAlign: 'center'
            }}
        >
            {!_.isNil(status) && t(`${statusId}${status.status}`, { ns: 'balanceCards' })}
            <br />
            {!_.isNil(status) && t(`${statusId}${status.status}Description`, { ns: 'balanceCards' })}
            <br />
            <br />
            <Button variant="contained" onClick={() => navigate(`/${encodeURIComponent(params.serialNumber)}`)}>
                {t('Terug naar overzicht')}
            </Button>
        </Box>
    );
}

export default TopupFinished;
