import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/misc/Loading';
import 'react-toastify/dist/ReactToastify.min.css';
import Theme from './components/misc/Theme';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from './useIntl';
import Layout from './components/layouts/Layout';
import NoCardId from './components/NoCardId';
import BalanceUpdates from './components/BalanceUpdates';
import Topup from './components/Topup';
import { BalanceCardProvider } from './useBalanceCard';
import TopupFinished from './components/TopupFinished';
import RegisterBalanceCard from './components/RegisterBalanceCard';

function ඞ() {
    return (
        <div className="App">
            <IntlProvider>
                <Theme>
                    <React.Suspense fallback={<Loading />}>
                        <BalanceCardProvider>
                            <Routes>
                                <Route element={<Layout />}>
                                    <Route index element={<NoCardId />} />
                                    <Route path="/:serialNumber" element={<BalanceUpdates />} />
                                    <Route path="/:serialNumber/register" element={<RegisterBalanceCard />} />
                                    <Route path="/:serialNumber/topup" element={<Topup />} />
                                    <Route path="/:serialNumber/topup/finish" element={<TopupFinished />} />
                                </Route>
                            </Routes>
                        </BalanceCardProvider>
                    </React.Suspense>

                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Theme>
            </IntlProvider>
        </div>
    );
}

export default ඞ;
