import PropTypes from 'prop-types';

function TimelineItem({ inverted = false, children }, props) {
    return (
        <li {...props} className={inverted ? 'timeline-inverted' : ''}>
            {children}
        </li>
    );
}

TimelineItem.propTypes = {
    inverted: PropTypes.bool,
    children: PropTypes.node
};

export default TimelineItem;
