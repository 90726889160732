import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useBalanceCard from '../useBalanceCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BalanceCard from '../api/endpoints/balanceCard';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const RegisterBalanceCard = () => {
    const { t } = useTranslation('balanceCard');

    const params = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { events, setSerialNumber, requiresRegistration, populateBalanceCard, serialNumber } = useBalanceCard();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [validationErrors, setValidationErrors] = useState({
        errors: false,
        firstName: {
            error: false
        },
        lastName: {
            error: false
        },
        emailAddress: {
            error: false
        },
        phoneNumber: {
            error: false
        }
    });

    useEffect(() => {
        populateBalanceUpdates();
    }, [params, pathname, events, requiresRegistration]);

    const populateBalanceUpdates = async () => {
        if (params.serialNumber.indexOf(':') === -1) {
            if (params.serialNumber.length >= 16) {
                const serialNumber = `0${Number(params.serialNumber).toString(16)}`.toUpperCase();
                navigate(`/${encodeURIComponent(serialNumber)}/register`, { replace: true });

                return;
            } else {
                const serialNumber = params.serialNumber.replaceAll(/.{2}\B/g, '$&:');
                navigate(`/${encodeURIComponent(serialNumber)}/register`, { replace: true });

                return;
            }
        } else if (pathname.indexOf(':') !== -1) {
            navigate(`/${encodeURIComponent(params.serialNumber)}/register`, { replace: true });

            return;
        }

        setSerialNumber(params.serialNumber);

        if (events !== null && !requiresRegistration) {
            navigate(`/${encodeURIComponent(params.serialNumber)}`);
        }
    };

    const validateInput = () => {
        let isValid = true;

        if (_.isEmpty(firstName)) {
            isValid = false;

            setValidationErrors((prevState) => {
                prevState.errors = true;
                prevState.firstName = {
                    error: true
                };

                return { ...prevState };
            });
        }

        if (_.isEmpty(lastName)) {
            isValid = false;

            setValidationErrors((prevState) => {
                prevState.errors = true;
                prevState.lastName = {
                    error: true
                };

                return { ...prevState };
            });
        }

        if (_.isEmpty(email)) {
            isValid = false;

            setValidationErrors((prevState) => {
                prevState.errors = true;
                prevState.emailAddress = {
                    error: true
                };

                return { ...prevState };
            });
        }

        if (_.isEmpty(phoneNumber)) {
            isValid = false;

            setValidationErrors((prevState) => {
                prevState.errors = true;
                prevState.phoneNumber = {
                    error: true
                };

                return { ...prevState };
            });
        }

        return isValid;
    };

    const handleSubmit = () => {
        if (!validateInput()) {
            return;
        }

        BalanceCard.register(serialNumber, firstName, lastName, email, phoneNumber).then(() => {
            populateBalanceCard();
        });
    };

    return (
        <>
            <Box
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}
                autoComplete="on"
            >
                <Typography variant="h4">{t('Registreer balanskaart')}</Typography>
                <Typography variant="body1">{t('Het evenement waar deze balanskaart aan is gekoppeld vereist registratie.')}</Typography>

                <TextField
                    label={t('Voornaam')}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="given-name"
                    error={validationErrors.firstName.error}
                />
                <TextField
                    label={t('Achternaam')}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="family-name"
                    error={validationErrors.lastName.error}
                />
                <TextField
                    label={t('E-mail')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    error={validationErrors.emailAddress.error}
                />
                <TextField
                    label={t('Telefoonnummer')}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    autoComplete="tel"
                    error={validationErrors.phoneNumber.error}
                />

                <Button onClick={handleSubmit}>{t('Verzenden')}</Button>
            </Box>
        </>
    );
};

export default RegisterBalanceCard;
