import NavMenu from './NavMenu';
import { Box, Breadcrumbs, Link, Toolbar, Typography } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Loading from '../misc/Loading';
import React, { useEffect, useState } from 'react';
import useBalanceCard from '../../useBalanceCard';
import { useTranslation } from 'react-i18next';

function Layout() {
    const { t } = useTranslation('balanceCard');

    const location = useLocation();
    const navigate = useNavigate();
    const { serialNumber } = useBalanceCard();

    const [page, setPage] = useState();

    useEffect(() => {
        if (location.pathname.includes('/topup')) {
            setPage('topup');
        } else if (location.pathname.includes('/register')) {
            setPage('register');
        } else {
            setPage('overview');
        }
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <NavMenu />

            <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
                <React.Suspense fallback={<Loading />}>
                    <Toolbar />
                    <Box
                        display="flex"
                        sx={{
                            justifyContent: 'center',
                            marginBottom: '1rem'
                        }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography>{t('Balanskaart')}</Typography>
                            {page === 'overview' ? (
                                <Typography color="text.primary">{t('Overzicht')}</Typography>
                            ) : (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => navigate(`/${encodeURIComponent(serialNumber)}`)}
                                >
                                    {t('Overzicht')}
                                </Link>
                            )}

                            {page === 'topup' && <Typography color="text.primary">{t('Opwaarderen')}</Typography>}
                            {page === 'register' && <Typography color="text.primary">{t('Registreren')}</Typography>}
                        </Breadcrumbs>
                    </Box>
                    <Outlet />
                </React.Suspense>
            </Box>
        </Box>
    );
}

export default Layout;
