import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function ErrorFallback({ error, resetErrorBoundary }) {
    const { t } = useTranslation(['translation', 'error']);

    return (
        <Box
            role="alert"
            sx={{
                textAlign: 'center'
            }}
        >
            <Typography variant="body1">{t('somethingWentWrong', { ns: 'error' })}</Typography>
            <pre>{error.message}</pre>
        </Box>
    );
}

ErrorFallback.propTypes = {
    error: PropTypes.object,
    resetErrorBoundary: PropTypes.func
};

export default ErrorFallback;
