import { useTranslation } from 'react-i18next';
import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const IntlContext = createContext({});

export function IntlProvider({ children }) {
    const { t, i18n } = useTranslation();

    const numberFormat = new Intl.NumberFormat(i18n.language, { style: 'decimal', maximumFractionDigits: 2 });
    const intFormat = new Intl.NumberFormat(i18n.language, { style: 'decimal', maximumFractionDigits: 0 });
    const currencyFormat = new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 });
    const percentFormat = new Intl.NumberFormat(i18n.language, { style: 'percent', maximumFractionDigits: 2 });
    const conjunctionListFormat = new Intl.ListFormat(i18n.language, { type: 'conjunction' });
    const disjunctionListFormat = new Intl.ListFormat(i18n.language, { type: 'disjunction' });

    const memoedValue = useMemo(
        () => ({
            numberFormat,
            currencyFormat,
            percentFormat,
            intFormat,
            conjunctionListFormat,
            disjunctionListFormat
        }),
        [t, i18n]
    );

    return <IntlContext.Provider value={memoedValue}>{children}</IntlContext.Provider>;
}

IntlProvider.propTypes = {
    children: PropTypes.object
};

export default function useIntl() {
    return useContext(IntlContext);
}
