const map = {
    1: {
        name: 'Cash',
        url: '/assets/payment_method_icons/token.png'
    },
    2: {
        name: 'Balanskaart',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    3: {
        name: 'Top-up (cash)',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    4: {
        name: 'Offline'
    },
    5: {
        name: 'Top-up (pin)',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    6: {
        name: 'Tap-to-pay',
        url: '/assets/payment_method_icons/viva.png'
    },
    7: {
        name: 'Munt',
        url: '/assets/payment_method_icons/token.png'
    },
    8: {
        name: 'split'
    },
    // Maestro pin
    1630: {
        brandId: 6,
        name: 'maestroDebit'
    },
    // Mastercard pin
    1636: {
        brandId: 8,
        name: 'mastercardCredit'
    },
    // Online payment, pending method selection
    1663: {
        brandId: 167,
        name: 'online'
    },
    // Visa pin
    2369: {
        brandId: 34,
        name: 'visaCredit'
    },
    // Pin pending
    1927: {
        brandId: 164,
        name: 'pin'
    },
    // Pin eigen contract
    1723: {
        brandId: 164,
        name: 'pinEigenContract'
    },
    // AmEx pin
    2759: {
        brandId: 9,
        name: 'amexCredit'
    },
    // Visa debit NL
    3051: {
        brandId: 34,
        name: 'visaDebit'
    },
    // Just AmEx, idk what the difference is
    1705: {
        brandId: 9,
        name: 'amexCredit'
    },
    // V-Pay pin
    1633: {
        brandId: 32,
        name: 'vpayDebit'
    },
    // iDeal
    10: {
        brandId: 1,
        name: 'iDeal'
    },
    // Mastercard debit NL
    3048: {
        brandId: 8,
        name: 'Mastercard debit'
    },
    436: {
        brandId: 2,
        name: 'Bancontact'
    },
    706: {
        brandId: 2277,
        name: 'Apple Pay'
    },
    712: {
        brandId: 114,
        name: 'Apple Pay'
    },
    2277: {
        brandId: 114,
        name: 'Apple Pay'
    },
    2351: {
        name: 'Retourpin',
        url: '/assets/payment_method_icons/pinRefund.png'
    },
    balanceCard: {
        name: 'Balanskaart',
        url: '/assets/payment_method_icons/balancecard.png'
    },
    cash: {
        name: 'Cash'
    },
    pin: {
        name: 'Pin'
    },
    softpos: {
        name: 'Tap-to-pay'
    },
    mobile_app: {
        name: 'N/A'
    }
};

export function getPaymentProfileName(paymentProfileId) {
    const mapValue = map[paymentProfileId];

    if (mapValue !== undefined && Object.hasOwn(mapValue, 'name')) {
        return mapValue.name;
    }

    return paymentProfileId;
}
