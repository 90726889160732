import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import { enUS as coreEnUS, nlNL as coreNlNL } from '@mui/material/locale';
import { useTranslation } from 'react-i18next';

function Theme({ children }) {
    const pallette = {
        mode: 'light',
        primary: {
            main: '#f2690d',
            light: '#ff9a44',
            dark: '#b83900',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#ffab91',
            light: '#ffddc1',
            dark: '#c97b63',
            contrastText: '#000000'
        },
        info: {
            main: 'rgba(2,136,209,0.75)'
        },
        success: {
            main: 'rgba(46,125,50,0.75)'
        },
        warning: {
            main: 'rgba(237,108,2,0.75)'
        },
        error: {
            main: '#b00b1e'
            // main: 'rgba(211,47,47,0.75)'
        },
        white: {
            main: 'rgba(255, 255, 255, 1)'
        }
    };

    let theme = createTheme({
        palette: pallette,
        theme: {
            accent: {
                light: '#ffab91',
                dark: '#f2690d'
            }
        },
        components: {
            MuiListItemButton: {
                variants: [
                    {
                        props: { variant: 'navbar' },
                        style: {
                            borderStartEndRadius: 360,
                            borderEndEndRadius: 360,
                            marginRight: '.25rem'
                        }
                    }
                ]
            }
        }
    });

    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}

Theme.propTypes = {
    children: PropTypes.array
};

export default Theme;
