import { useEffect, useRef, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getMediaDevices } from './utils/mediaDevices';

export const useMediaDevices = ({ constraints = null, onError = () => {} } = {}) => {
    const [mediaDevices, setMediaDevices] = useState(null);
    const errorHandlerRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        errorHandlerRef.current = onError;
    }, [onError]);

    useDeepCompareEffect(() => {
        const ac = new AbortController();

        new Promise((resolve, reject) => {
            ac.signal.addEventListener('abort', reject);
            setLoading(true);
            getMediaDevices(constraints).then(resolve);
        })
            .then((devices) => {
                setMediaDevices(devices);
            })
            .catch((error) => {
                if (error.type === 'abort') return;
                errorHandlerRef.current?.(error);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            ac.abort();
        };
    }, [constraints]);

    return { devices: mediaDevices, loading };
};
