import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Locize)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['nl', 'en-GB', 'de'],
        fallbackLng: 'nl',
        saveMissing: true,
        debug: process.env.NODE_ENV !== 'production',
        interpolation: {
            escapeValue: false
        },
        backend: {
            projectId: process.env.REACT_APP_LOCIZE_PROJECT,
            apiKey: process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_LOCIZE_API_KEY
        }
    })
    .then(() => {
        i18n.services.formatter.add('lowercase', (value, lng, options) => {
            return value.toLowerCase();
        });
    });

export default i18n;
