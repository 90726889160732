import { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import { useMediaDevices } from '../useMediaDevices';
import { useTranslation } from 'react-i18next';

const mediaConstraints = {
    video: true,
    audio: false
};

function NoCardId() {
    const { t } = useTranslation('balanceCard');

    const onMediaDevicesError = (error) => {
        console.error(error);
    };

    const { devices } = useMediaDevices({ constraints: mediaConstraints, onError: onMediaDevicesError });

    const navigate = useNavigate();

    const [scanning, setScanning] = useState(false);

    const qrRef = useZxing({
        paused: !scanning,
        onDecodeResult(result) {
            setScanning(false);
            const data = result.getText();

            if (data.startsWith('https://') || data.startsWith('http://')) {
                const parts = data.split('/');
                let serial = parts[parts.length - 1];

                if (!serial.includes(':')) {
                    const serialParts = serial.match(/.{1,2}/g);
                    serial = serialParts.join(':');
                }

                navigate(`/${serial}`);
            } else {
                navigate(`/${data}`);
            }
        }
    });

    const [hasCamera, setHasCamera] = useState(false);

    useEffect(() => {
        checkCamera().then((result) => setHasCamera(result));
    }, [devices]);

    const checkCamera = async () => {
        return devices?.length > 0;
    };

    const startScanning = () => {
        setScanning(true);
    };

    return (
        <>
            <Grid2 container>
                <Grid2 xs={0} sm={1} md={2} lg={3} xl={4} />
                <Grid2 xs={12} sm={10} md={8} lg={6} xl={4}>
                    <>
                        {hasCamera ? (
                            <>
                                <Card>
                                    <CardHeader title="Scan je kaart" />
                                    <CardContent>
                                        {t('Om je balans op te waarderen, moet je de QR-code op je balanskaart scannen.')}
                                    </CardContent>
                                    <CardActions>
                                        {!scanning && (
                                            <Button variant="contained" onClick={startScanning}>
                                                {t('Scan QR code')}
                                            </Button>
                                        )}
                                    </CardActions>
                                </Card>
                                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                <video
                                    style={{
                                        width: '100%'
                                    }}
                                    ref={qrRef.ref}
                                />
                            </>
                        ) : (
                            <Card>
                                <CardHeader title={t('Geen toegang')} />
                                <CardContent>
                                    <Typography>
                                        {t(
                                            'Kon de camera niet openen. Als dit apparaat wel een camera heeft, heeft de browser waarschijnlijk geen permissie om die te gebruiken.'
                                        )}
                                    </Typography>
                                    <Typography>
                                        {t(
                                            'Op Android, ga naar instellingen, apps, jouw browser app (Chrome/Firefox etc.), permissies, camera en kies iets anders dan niet toestaan.'
                                        )}
                                    </Typography>
                                    <Typography>
                                        {t(
                                            'Op iOS, ga naar instellingen, ga naar instellingen, apps, jouw browser app, en zet de camera permissie aan.'
                                        )}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                    </>
                </Grid2>
            </Grid2>
        </>
    );
}

export default NoCardId;
