import { AppBar, Box, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Toolbar } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, Settings } from 'luxon';
import { Translate } from '@mui/icons-material';

function NavMenu() {
    const { t, i18n } = useTranslation(['navbar', 'locales']);

    const [languages, setLanguages] = useState({ nl: { nativeName: 'Nederlands, Vlaams' } });
    const [language, setLanguage] = useState(i18n.language ?? 'nl');
    const [logoUrl, setLogoUrl] = useState('/assets/logo.png');
    const [mode, setMode] = useState('default');
    const [anchorElement, setAnchorElement] = useState(null);

    const currentDate = useMemo(() => DateTime.now(), []);

    useEffect(() => {
        if (currentDate.month === 12 && currentDate.day <= 26) {
            setMode('christmas');
            setLogoUrl('/assets/logo-christmas.png');
        } else if ((currentDate.month === 12 && currentDate.day > 26) || (currentDate.month === 1 && currentDate.day === 1)) {
            setMode('newyears');
            setLogoUrl('/assets/logo-fireworks.png');
        } else if (currentDate.month === 10) {
            setMode('halloween');
            setLogoUrl('/assets/logo-halloween.png');
        }
    }, [currentDate]);

    useEffect(() => {
        i18n.services.backendConnector.backend.getLanguages((err, result) => {
            if (err) return;
            setLanguages(result);
        });
    }, []);

    useEffect(() => {
        Settings.defaultLocale = language;
        i18n.changeLanguage(language);
    }, [language]);

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    const handleLanguageMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleLanguageClose = () => {
        setAnchorElement(null);
    };

    return (
        <>
            <AppBar position="fixed" color="primary">
                <Toolbar
                    sx={() => {
                        switch (mode) {
                            case 'christmas':
                                return {
                                    backgroundImage: 'url(/assets/snow.png)',
                                    backgroundSize: 'contain'
                                };
                            default:
                                return {};
                        }
                    }}
                    disableGutters
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            ml: '1rem',
                            overflow: 'hidden'
                        }}
                    >
                        <img
                            src={logoUrl}
                            alt={t('paytreeLogo', { ns: 'translation' })}
                            loading="lazy"
                            style={{
                                height: '3.5rem'
                            }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 0, mr: '1rem' }}>
                        <IconButton size="large" aria-label="Taal" aria-controls="language-menu" onClick={handleLanguageMenu} color="white">
                            <Translate />
                        </IconButton>
                        <Menu
                            id="language-menu"
                            anchorEl={anchorElement}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(anchorElement)}
                            onClose={handleLanguageClose}
                        >
                            <MenuItem>
                                <FormControl>
                                    <InputLabel id="language-select-label">Taal</InputLabel>
                                    <Select value={language} labelId="language-select-label" label="Taal" onChange={handleLanguageChange}>
                                        {Object.keys(languages).map((language) => (
                                            <MenuItem key={language} value={language}>
                                                {languages[language].nativeName}{' '}
                                                {'region' in languages[language] && `(${languages[language].region})`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default NavMenu;
