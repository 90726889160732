import fetcher from '../../fetcher';

export default class BalanceCard {
    static getBalanceUpdates(serialNumber, options, showToast, t) {
        options = {
            ...options,
            method: 'GET'
        };

        return fetcher(`v1/balanceCard/${encodeURIComponent(serialNumber)}/updates`, true, options, 'balanceCard', showToast, t).then(
            async (result) => {
                if (result) {
                    if (result.ok) {
                        result = await result.json();

                        return result;
                    }
                }
            }
        );
    }

    static getEvents(serialNumber, options, showToast, t) {
        options = {
            ...options,
            method: 'GET'
        };

        return fetcher(`v1/balanceCard/${encodeURIComponent(serialNumber)}/events`, true, options, 'events', showToast, t).then(
            async (result) => {
                if (result) {
                    if (result.ok) {
                        result = await result.json();

                        return result;
                    }
                }
            }
        );
    }

    static startTransaction(serialNumber, amount, eventDateId, options, t) {
        options = {
            ...options,
            method: 'POST',
            body: JSON.stringify({
                amount: amount,
                eventDateId: eventDateId
            })
        };

        return fetcher(`v1/balanceCard/topup/${encodeURIComponent(serialNumber)}/iDeal`, true, options, null, false, t).then(
            async (result) => {
                return result;
            }
        );
    }

    static register(serialNumber, firstName, lastName, emailAddress, phoneNumber, options, t) {
        options = {
            ...options,
            method: 'POST',
            body: JSON.stringify({
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                phoneNumber: phoneNumber
            })
        };

        return fetcher(`v1/balanceCard/${encodeURIComponent(serialNumber)}/register`, true, options, null, false, t).then(
            async (result) => {
                if (result && result.ok) {
                    return true;
                }

                return false;
            }
        );
    }
}
