import { forwardRef } from 'react';
import useIntl from '../../useIntl';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const DecimalInput = forwardRef(function DecimalInput(props, ref) {
    const { onChange, ...other } = props;
    const { numberFormat } = useIntl();

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({ target: { value: values.value } });
            }}
            thousandSeparator={numberFormat.format(1000).substring(1, 2)}
            decimalSeparator={numberFormat.format(1.1).substring(1, 2)}
            isNumericString
        />
    );
});

DecimalInput.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default DecimalInput;
