import { CircularProgress } from '@mui/material';

function Loading() {
    return (
        <>
            <CircularProgress
                size={50}
                sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    marginTop: '-25px',
                    marginBottom: '-25px'
                }}
            />
        </>
    );
}

export default Loading;
