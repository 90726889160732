import PropTypes from 'prop-types';

function TimelineBadge({ icon, color = 'success' }, props) {
    return (
        <div {...props} className={`timeline-badge ${color}`}>
            {icon}
        </div>
    );
}

TimelineBadge.propTypes = {
    icon: PropTypes.object,
    color: PropTypes.string
};

export default TimelineBadge;
