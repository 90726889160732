import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function TimelineHeading({ title, subtitle }, props) {
    return (
        <div {...props} className="timeline-heading">
            <Typography variant="h5" className="timeline-title">
                {title}
            </Typography>
            <Typography className="timeline-subtitle" variant="subtitle2">
                {subtitle}
            </Typography>
            <br />
        </div>
    );
}

TimelineHeading.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node
};

export default TimelineHeading;
