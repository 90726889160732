import PropTypes from 'prop-types';

function TimelineBody({ children }, props) {
    return (
        <div {...props} className="timeline-body">
            {children}
        </div>
    );
}

TimelineBody.propTypes = {
    children: PropTypes.node
};

export default TimelineBody;
