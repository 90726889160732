const cancelStream = (stream) => {
    stream.getTracks().forEach((track) => track.stop());
};

const requestPermission = async (constraints = null) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    cancelStream(stream);
};

const hasNavigator = () => {
    return typeof navigator !== 'undefined';
};

const isMediaDevicesSupported = () => {
    return hasNavigator() && !!navigator.mediaDevices;
};

const canEnumerateDevices = () => {
    return !!(isMediaDevicesSupported() && navigator.mediaDevices.enumerateDevices);
};

export const getMediaDevices = async (constraints = null) => {
    if (!canEnumerateDevices()) throw new Error('MediaDevices not supported');
    await requestPermission(constraints);
    return await navigator.mediaDevices.enumerateDevices();
};
