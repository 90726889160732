/**
 * @param {string} url
 * @param {object} options
 *
 * @returns {Request}
 */
import { toast } from 'react-toastify';

const buildRequest = (url, options = {}) => {
    const headers = new Headers({
        ...options.headers,
        'Content-Type': 'application/json'
    });

    options.headers = headers;

    return new Request(url, { ...options });
};

/**
 * Fetches a resource and automatically adds authorization tokens to the headers.
 *
 * @param {string} url
 * @param {object?} options
 * @param {boolean?} forceRefresh
 * @param {string} thing
 *
 * @param showToast
 * @returns {Promise<Response>|boolean}
 */
async function fetcher(url, forceRefresh = false, options, thing = 'Object', showToast = true, t) {
    let result;
    if (url.startsWith('/') || process.env.REACT_APP_API_URL.endsWith('/')) {
        url = `${process.env.REACT_APP_API_URL}${url}`;
    } else {
        url = `${process.env.REACT_APP_API_URL}/${url}`;
    }

    if ((options !== undefined && options.method !== 'GET' && options.method !== 'HEAD') || true) {
        console.debug('Not caching request');
        result = await fetch(buildRequest(url, options));
    } else {
        result = await getData(buildRequest(url, options), forceRefresh);
    }

    if (!result) {
        return false;
    }

    if (!result.ok) {
        const resultText = await result.text();

        if (showToast && resultText !== null && resultText !== '') {
            toast.error(t(resultText, { ns: 'error' }));
        }
    } else if (result.status === 201 && showToast) {
        toast.success(`${thing} created`);
    } else if (options !== undefined && options.method === 'PATCH' && showToast) {
        toast.success(`${thing} updated`);
    }

    return result;
}

/**
 * @param {string} cacheName
 * @param {Request} url
 * @returns {Promise<Response>|boolean}
 */
const getCachedData = async (cacheName, url) => {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
        return false;
    }

    const responseDate = new Date(cachedResponse.headers.get('date')).getTime();
    const currentDate = new Date().getTime();

    // 5 minutes in milliseconds
    if (currentDate - responseDate >= 5 * 60 * 1000) {
        return false;
    }

    return cachedResponse;
};

/**
 * @param {Request} url
 * @param {boolean} forceRefresh
 *
 * @returns {Promise<Response> | Boolean}
 */
const getData = async (url, forceRefresh) => {
    const cacheName = 'api';

    if (!forceRefresh) {
        const cachedData = await getCachedData(cacheName, url);

        if (cachedData) {
            return cachedData;
        }
    }

    const cacheStorage = await caches.open(cacheName);

    try {
        return fetch(url).then(async (response) => {
            if (!response.ok) {
                return false;
            }

            await cacheStorage.put(url, response);

            return getCachedData(cacheName, url);
        });
    } catch (e) {
        return false;
    }
};

export default fetcher;
