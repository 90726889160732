import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BalanceCard from '../api/endpoints/balanceCard';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import DecimalInput from './misc/DecimalInput';
import useBalanceCard from '../useBalanceCard';
import { LoadingButton } from '@mui/lab';
import useIntl from '../useIntl';
import { toast } from 'react-toastify';
import _ from 'lodash';

function Topup() {
    const { t } = useTranslation('balanceCard');

    const params = useParams();
    const { pathname } = useLocation();
    const { setSerialNumber, serialNumber, events, requiresRegistration } = useBalanceCard();
    const navigate = useNavigate();
    const { currencyFormat } = useIntl();

    const [amount, setAmount] = useState('25.00');
    const [availableEvents, setAvailableEvents] = useState([]);
    const [selectedEventDate, setSelectedEventDate] = useState('');
    const [locks, setLocks] = useState([]);

    const [validationErrors, setValidationErrors] = useState({
        errors: false,
        amount: {
            helperText: 'Min. €1,00',
            error: false
        }
    });

    useEffect(() => {
        populateBalanceUpdates();
    }, [params, pathname, requiresRegistration]);

    useEffect(() => {
        populateEvents();
    }, []);

    useEffect(() => {
        if (amount < 1.0) {
            setValidationErrors((prevState) => {
                prevState.errors = true;
                prevState.amount = {
                    error: true,
                    helperText: `${t('Het minimumbedrag is')} €1,00`
                };

                return { ...prevState };
            });
        } else {
            setValidationErrors((prevState) => {
                prevState.errors = false;
                prevState.amount = {
                    error: false,
                    helperText: 'Min. €1,00'
                };

                return { ...prevState };
            });
        }
    }, [amount]);

    const setLock = (lock) => {
        setLocks((prevState) => {
            prevState.push(lock);

            return prevState;
        });
    };

    const releaseLock = (lock) => {
        setLocks((prevState) => {
            const index = prevState.indexOf(lock);
            prevState.splice(index, 1);

            return prevState;
        });
    };

    const populateEvents = async () => {
        setLock('populateEvents');

        const result = await BalanceCard.getEvents(params.serialNumber, {}, t);

        if (result) {
            setAvailableEvents(result);

            if (result.length === 1 && result[0].dates.length === 1) {
                setSelectedEventDate(result[0].dates[0]);
            }

            releaseLock('populateEvents');
        }
    };

    const populateBalanceUpdates = async () => {
        if (params.serialNumber.indexOf(':') === -1) {
            if (params.serialNumber.length >= 16) {
                const serialNumber = `0${Number(params.serialNumber).toString(16)}`.toUpperCase();
                navigate(`/${encodeURIComponent(serialNumber)}/topup`, { replace: true });

                return;
            } else {
                const serialNumber = params.serialNumber.replaceAll(/.{2}\B/g, '$&:');
                navigate(`/${encodeURIComponent(serialNumber)}/topup`, { replace: true });

                return;
            }
        } else if (pathname.indexOf(':') !== -1) {
            navigate(`/${encodeURIComponent(params.serialNumber)}/topup`, { replace: true });

            return;
        }

        setSerialNumber(params.serialNumber);

        // if (events !== null && requiresRegistration) {
        //     navigate(`/${encodeURIComponent(params.serialNumber)}/register`);
        // }
    };

    const handleEventChange = (event) => {
        setSelectedEventDate(event.target.value);
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleCheckoutClick = async () => {
        if (_.isEmpty(selectedEventDate)) return;

        const result = await BalanceCard.startTransaction(serialNumber, amount, selectedEventDate.id);

        if (result && result.ok) {
            const json = await result.json();
            window.location = json.transaction.paymentURL;
        } else if (result.status === 409) {
            navigate(`/${encodeURIComponent(params.serialNumber)}/register`);
        } else {
            toast.error(t('Onbekende fout, probeer het later opnieuw.'));
        }
    };

    const getEventDateArray = (events) => {
        const result = [];

        events.forEach((event) => {
            event.dates.forEach((eventDate) => {
                result.push(
                    <MenuItem key={eventDate.id} value={eventDate}>
                        {event.name} - {event.organisation} ({DateTime.fromISO(eventDate.dateStart).toLocaleString(DateTime.DATE_MED)}—
                        {DateTime.fromISO(eventDate.dateEnd).toLocaleString(DateTime.DATE_MED)})
                    </MenuItem>
                );
            });
        });

        return result;
    };

    return (
        <Box
            display="flex"
            sx={{
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <Card
                sx={{
                    width: {
                        xs: '100%',
                        sm: '75%',
                        md: '50%',
                        xl: '40%'
                    }
                }}
            >
                <CardHeader title={t('Opwaarderen')} />
                <CardContent>
                    <TextField
                        label={t('Bedrag')}
                        fullWidth
                        margin="dense"
                        helperText={validationErrors.amount.helperText}
                        error={validationErrors.amount.error}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            inputComponent: DecimalInput
                        }}
                        value={amount}
                        onChange={handleAmountChange}
                    />
                    <FormControl margin="dense" fullWidth>
                        <InputLabel>{t('Evenement')}</InputLabel>
                        <Select
                            label={t('Evenement')}
                            value={selectedEventDate}
                            disabled={availableEvents.length <= 0}
                            onChange={handleEventChange}
                        >
                            {getEventDateArray(availableEvents)}
                        </Select>
                    </FormControl>
                    {availableEvents.length !== 1 && (
                        <>
                            <Alert severity="warning" sx={{ mt: '8px', mb: '4px' }}>
                                {availableEvents.length <= 0 && (
                                    <>
                                        <AlertTitle>{t('Let op!')}</AlertTitle>
                                        <p>
                                            {t(
                                                'Deze kaart is nog niet gekoppeld aan een evenement, en er zijn op dit moment geen actieve evenementen.'
                                            )}
                                        </p>
                                        <p>{t('Het is op dit moment niet mogelijk om deze balanskaart online op te waarderen.')}</p>
                                    </>
                                )}
                                {availableEvents.length > 1 && (
                                    <>
                                        <p>{t('Deze kaart is nog niet bij ons bekend, of is op meerdere evenementen gebruikt.')}</p>
                                        <p>{t('Bovenstaand selectieveld vereist dat het correcte evenement gekozen wordt.')}</p>
                                        <p>
                                            {t(
                                                'Indien de evenement-/organisatornaam bij u niet bekend is, kunt u dit aan de organisatie vragen.'
                                            )}
                                        </p>
                                        <p>
                                            {t(
                                                'Bij foutieve selectie is uw opwaardeertegoed niet bruikbaar op het evenement en wordt dit niet gerestitueerd.'
                                            )}
                                        </p>
                                    </>
                                )}
                            </Alert>
                        </>
                    )}
                    <Box sx={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}>
                        <TableContainer sx={{ mt: '8px', mb: '4px', maxWidth: '50%' }} component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>{t('Item')}</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>{t('Prijs')}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{t('Balans')}</TableCell>
                                        <TableCell align="right">{currencyFormat.format(amount)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{t('Servicekosten')}</TableCell>
                                        <TableCell align="right">{currencyFormat.format(0.0)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>{t('Totaal')}</b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>{currencyFormat.format(parseFloat(amount))}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </CardContent>
                <CardActions>
                    <LoadingButton
                        loading={locks.length > 0}
                        variant="contained"
                        disabled={validationErrors.errors || availableEvents.length <= 0}
                        onClick={handleCheckoutClick}
                    >
                        {t('checkout')}
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    );
}

export default Topup;
